import { create } from 'ipfs-http-client'

const ipfsUrl = 'https://ipfs.fatsale.org'
const ipfsGatewayUrl = 'https://ipfs.gateway.fatsale.org'
// 上传文件
export const uploadFile = async (file) => {
    const client = await create(ipfsUrl)
    const added = await client.add(file)
    console.log(added)
    return added
}

// 保存文字
export const uploadText = async (text) => {
    const client = await create(ipfsUrl)
    const added = await client.add(text)
    console.log(added)
    return added
}


export const getIpfsUrl = (key) => {
    return `${ipfsGatewayUrl}/ipfs/${key}`
}

// 获取保存的文字
export const getIpfsText = async (key) => {
    const url = getIpfsUrl(key)
    const resp = await fetch(url)
    let text = await resp.text()
    if (isJson(text)) {
        text = JSON.stringify(traverseAndReplace(JSON.parse(text)))
    }
    return text
}

const traverseAndReplace = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object') {
                // 如果当前属性是对象，递归遍历子属性
                traverseAndReplace(obj[key]);
            } else if (typeof obj[key] === 'string') {
                // 如果当前属性是字符串，替换指定的子串
                obj[key] = obj[key].replace('https://ipfs.gateway.token-monitor.com', ipfsGatewayUrl);
                obj[key] = obj[key].replace('https://ipfs.gateway.fatsale.finance', ipfsGatewayUrl);
            }
        }
    }
    return obj
}

const isJson = (str) => {
    if (typeof str !== 'string') {
        return false
    }

    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
}
import React, { useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { Contract, Provider } from "ethers-multicall";
import { initProvider, nftAbi, tokenAbi } from "../../../../utils/config";
import { ethers } from "ethers";
import StakeModal from ".";


export default function MyNFTListModal(props) {
    const [currentProvider, setCurrentProvider] = useState(null); // 当前的provider
    const [nftIsPair, setNftIsPair] = useState(false); // 当前的nft是否是pair
    const [stakeInfo, setStakeInfo] = useState({}); // 质押信息
    const [list, setList] = useState([]); // nft列表
    const [showStakeModal, setShowStakeModal] = useState(false); // 是否显示质押modal

    const { t } = useTranslation();
    let stakeModalRef = React.createRef()

    useImperativeHandle(props.onRef, () => {
        // 需要将暴露的接口返回出去
        return {
            setInfo: setInfo
        };
    });
    const setInfo = (info) => {
        console.log(info)
        setStakeInfo(info)

    }


    const initWalletProvider = () => {
        if (currentProvider) {
            return currentProvider
        }
        if (window.ethereum && !currentProvider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            setCurrentProvider(provider)
            return provider
        } else {
            console.log('No provider')
        }
    }
    const getNftList = async (page) => {
        const { wallet, miningContract, chainId, nftAddress } = stakeInfo
        if (!wallet || !miningContract || !chainId || !nftAddress) return

        // 先按照pair的获取, 报错之后按照erc721a获取
        const provider = initWalletProvider()
        let ethcallProvider = new Provider(provider)
        ethcallProvider = await initProvider(ethcallProvider, chainId)

        const _nft = new Contract(nftAddress, nftAbi)
        const nftCall = [_nft.name(), _nft.symbol(), _nft.totalSupply(), _nft.balanceOf(wallet)]
        const nftRes = await ethcallProvider.all(nftCall)
        const tmpNftInfo = {
            name: nftRes[0],
            symbol: nftRes[1],
            totalSupply: nftRes[2].toString(),
            balance: nftRes[3].toString(),
            balanceNumber: nftRes[3],
        }

        const b = parseInt(tmpNftInfo.balance)

        // 获取持有的nft的列表
        const abi = [
            'function tokenOfOwnerByIndex(address owner, uint256 index) external view returns (uint256)',
            'function tokenURI(uint256 tokenId) external view returns (string memory)',
            'function tokensOfOwner(address owner) external view returns (uint256[] memory)',
            'function tokenURI(uint256 tokenId) external view returns (string memory)',
            'function getApproved(uint256 tokenId) external view returns (address)',
            'function positions(uint256 tokenId) external view returns (uint96,address,address,address,uint24,int24,int24,uint128,,uint256,uint256,uint128,uint128)',
        ]
        const _n = new Contract(nftAddress, abi)
        let nftIsPair = false
        const idList = []

        try {
            // 用v3池子来取
            const call = []
            for (let i = 0; i < b; i++) {
                call.push(_n.tokenOfOwnerByIndex(wallet, i))
            }
            const v3PoolNftRes = await ethcallProvider.all(call)
            const calls2 = []
            for (let i = 0; i < b; i++) {
                idList.push({
                    address: nftAddress,
                    id: parseInt(v3PoolNftRes[i].toString()),
                    approved: false,
                    positions: {},
                })
                calls2.push(_n.getApproved(v3PoolNftRes[i]))
                calls2.push(_n.positions(v3PoolNftRes[i]))
            }
            const v3PoolNftRes2 = await ethcallProvider.all(calls2)
            let ind = 0
            const tokenList = []
            for (let i = 0; i < b; i++) {
                const approved = false
                ind += 1
                const positions = v3PoolNftRes2[ind]
                idList[i].approved = approved
                idList[i].positions = positions
                // 代币加进去
                tokenList.push(positions[2])
                tokenList.push(positions[3])
                ind += 1
            }

            // 获取代币信息
            const calls3 = []
            for (let i = 0; i < tokenList.length; i++) {
                const _t = new Contract(tokenList[i], tokenAbi)
                calls3.push(_t.symbol())
            }

            const tokenRes = await ethcallProvider.all(calls3)
            const tokenMap = {}
            for (let i = 0; i < tokenList.length; i++) {
                tokenMap[tokenList[i].toLowerCase()] = tokenRes[i]
            }

            for (let i = 0; i < idList.length; i++) {
                const positions = idList[i].positions
                const token0Symbol = tokenMap[positions[2].toLowerCase()]
                const token1Symbol = tokenMap[positions[3].toLowerCase()]
                idList[i].token0Symbol = token0Symbol
                idList[i].token1Symbol = token1Symbol
                idList[i].token0 = positions[2]
                idList[i].token1 = positions[3]
                idList[i].liquidity = (positions[7].toString() / 1e18).toFixed(4)
                idList[i].isPair = true
            }

            nftIsPair = true
        } catch (e) {
            // 按照erc721协议来取
            const call = [_n.tokensOfOwner(wallet)]
            const v3PoolNftRes = await ethcallProvider.all(call)
            const list = v3PoolNftRes[0]
            for (let i = 0; i < list.length; i++) {
                idList.push({
                    address: nftAddress,
                    id: parseInt(list[i].toString()),
                    approved: false,
                    isPair: false,
                })
            }
            // const calls2 = []
            // for (let i = 0; i < list.length; i++) {
            //     calls2.push(_n.getApproved(list[i]))
            // }
            // const v3PoolNftRes2 = await ethcallProvider.all(calls2)
            // for (let i = 0; i < list.length; i++) {
            //     idList[i].approved = false
            // }
            nftIsPair = false
        }

        setNftIsPair(nftIsPair)
        console.log(idList, '======')
        setList(idList)

    }

    useEffect(() => {
        getNftList(1)
    }, [stakeInfo])
    // useEffect(() => {
    //     // 获取nft列表
    //     getNftList(1)
    // }, [props.wallet, props.miningContract])
    return <>
        <StakeModal
            onRef={stakeModalRef}
            show={showStakeModal}
            handleClose={() => {
                setShowStakeModal(false)
            }}
            ok={() => {
                // 质押完成
                getNftList(1)
                props.ok && props.ok()
            }} show={showStakeModal} />
        <Modal centered show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            <Modal.Body>
                <div style={{ 'backgroundColor': '#090a19' }} className="full-width">
                    <div className="nft-list-modal">
                        <h4>{t('质押NFT')}</h4>
                    </div>
                    <div className="stake-nft-body" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                        {list.length === 0 && <div> {t('暂无可质押NFT')} </div>}
                        {list.length > 0 && <div className="full-width">
                            <Table style={{ 'backgroundColor': '#090a19', width: '100%' }} responsive hover variant="dark">
                                <thead>
                                    <tr>
                                        <th className="nft-list-table-th">Token ID</th>
                                        {nftIsPair && <th className="nft-list-table-th">{t('交易对')}</th>}
                                        {nftIsPair && <th className="nft-list-table-th">{t('流动性')}</th>}
                                        {nftIsPair && <th className="nft-list-table-th "></th>}
                                        <th className={`nft-list-table-th ${nftIsPair ? 'fixed-right-td' : ''}`}>{t('操作')}</th>

                                    </tr>
                                </thead>
                                <tbody >
                                    {list.length > 0 && list.map((item, ind) => {
                                        return <tr key={'nft-list-' + ind}>
                                            <td className="nft-list-table-th nft-list-table-td">{item.id}</td>
                                            {nftIsPair && <td className="nft-list-table-th nft-list-table-td">{item.token0Symbol} / {item.token1Symbol}</td>}
                                            {nftIsPair && <td className="nft-list-table-th nft-list-table-td">{item.liquidity}</td>}
                                            {nftIsPair && <td className="nft-list-table-th nft-list-table-td"></td>}
                                            <td className={`nft-list-table-th nft-list-table-td ${nftIsPair ? 'fixed-right-td' : ''}`}>
                                                <button className="btn btn-smaller" onClick={async () => {
                                                    // 质押
                                                    const { wallet, miningContract, chainId, nftAddress } = stakeInfo
                                                    console.log(stakeModalRef.current, '=====')
                                                    await stakeModalRef.current.setApproveInfo({
                                                        // 授权信息
                                                        // stakeContract: stakeInfo.nftAddress,
                                                        ...stakeInfo,
                                                        tokenId: item.id,
                                                    })
                                                    stakeModalRef.current.handleLogic()

                                                    setShowStakeModal(true)
                                                }}>{t('质押')}</button>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>

                            </Table>
                        </div>}
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    </>

}